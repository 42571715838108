.row {
    display: flex !important;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

@media  screen and (max-width:470px  ){
    .MyParallax{
        height:128px !important;
    }
    .AproposTitle{
        font-size: 2em !important;
        margin-top: 3%;
    }
}

@media  screen and (max-width:470px  ){

    .AproposTitle{
        font-size: 1.8em !important;
        margin-top: 3%;
    }
    .DivTitle{
        margin-top: 28% !important;
    }
}
