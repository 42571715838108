#five{
    color:white;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: white !important;
}

@media only screen and (max-width: 690px) {
    .CopyRightFooter{
        display:flex;
        flex-direction:column !important;
        margin-left:28% !important;
    }
  }