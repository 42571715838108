@media  screen and (max-width:470px  ){

    .RestoParallax{
height: 128px !important;
    }

    .RestaurantTitle{
        font-size: 1.8em !important;
        margin-top: 3%;
    }
    .DivTite{
        margin-top: 28% !important;
    }
}
