
.MuiBox-root {
    padding: 0px !important;
}
.bg { 
    
    /* The image used */
    background-image: url("./Images/client.jpg");
  
    /* Full height */
    height: 100%; 
  
    /* Center and scale the image nicely */
    background-repeat: no-repeat;
    background-size: cover;
  }