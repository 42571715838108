


@media screen and (max-width: 992px){
.Mycol{
    margin-left:9.5%
}
.toptop{
    margin-top: 6%;
}
}

@media screen and (max-width: 576px){
    .MyRow{
        display: grid !important;
    }
    .Mycol{
        margin-left: 9.5% !important;
    }
    .toptop{
        margin-top: 6%;
    }
    }


    @media screen and (max-width: 425px){
        .Mycol{
            margin-right: 20% !important;
        }
    }