.IconText{
    display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 1rem;
  margin-top: 6% ;
  margin-bottom: 6%;
  
}


@media screen and (max-width:625px){
    .carsouel{
        margin-top:5%
    }
}
@media screen and (max-width:474px){
    .carsouel{
        margin-top:12%
    }
}
@media screen and (max-width:475px){
    .carsouel{
        margin-top:12%
    }
}
@media screen and (max-width:570px) and (orientation:landscape){
    .carsouel{
        margin-top:9.5%
    }
}