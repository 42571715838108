.MuiAppBar-root {
    width: 100%;
    display: flex;
    z-index: 1100;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-direction: column;
    position: fixed;
}

@media screen and (max-width:740px){
    .plafond{
        margin-top: 80px !important;
    }
}
@media screen and (max-width:330px){
    .plafond{
        margin-top: 100px !important;
    }
}
.PrivateTabIndicator-colorPrimary-3 {
    background-color: #82bb25 !important;
}
