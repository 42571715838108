@media screen and (max-width:992px){

    .ColParag{
        margin-top:4% !important ;
    }
    .ColParag2{
        margin-top:0% !important ;
    }
}

@media  screen and (max-width:470px  ){

    .ChauffeurTitle{
        font-size: 1.8em !important;
        margin-top: 3%;
    }
    .DivTitlle{
        margin-top: 28% !important;
    }
    .ChauffParallax{
        height:128px!important
    }
}
