.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }
}
#UiCard {
    will-change: transform;
    transition: transform 450ms;
    cursor: pointer
}

#UiCard:hover {
    transition: transform 125ms;
    transform: translateY(-10px);
}
