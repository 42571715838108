.navbar-brand-second {
    display: inline-block;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}
.navbar-second {
    position: fixed;
    top: 0;
    z-index: 99999;
    
}
#ThirdNav{
    
    top: 0;
    width: 100%;
    transition: .5s;
    z-index: 9999;
}



.navbar-light .navbar-nav .nav-link {
    color: white !important;
}

 #MyLinkNav {
    will-change: transform;
    transition: transform 450ms;
  }

   #MyLinkNav:hover {
    transition: transform 125ms;
    transform: translateY(-2px);
  }
.ShadowNav{
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 37%);
}