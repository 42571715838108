.LocationTtitle{
    height: auto;
    width: 100%;
    z-index: 1;
    text-align: left;
    font-size: 4.5em;
    font-weight: 750;
    font-family: 'Poppins',sans-serif !important;
    margin-top:15%;
    margin-left:4%;
    color:white;

}

@media  screen and (max-width:470px  ){
    .MyParallax2{
        height:128px !important;
    }

}

@media  screen and (max-width:470px  ){

    .LocationTtitle{
        font-size: 1.2em !important;

    }
    .TitleLocation{
        margin-top: 28% !important;
    }
    .GpsImg{
        height:30px;
        width:30px;
    }
}

@media  screen and (max-width:571px  ){

    .LocationTtitle{
        font-size: 3.2em
    }

}
