/* correction suplémentaires landscape */

@media screen and (max-width:570px) and (orientation: landscape) {
    .flex-row{
        top: 15% !important;
    }
}




@media screen and (max-width:1024px){
    .TransportText{
        margin-left: 11% !important
    }
}

@media screen and (max-width:768px){
    .TransportText{
        margin-right: 12%;
    }
}


@media screen and (max-width:320px){

  .row{
      width:90% !important;
      margin-left: 5% !important ;
      margin-right: 5% !important;
  }

    .BananaBloc{
        margin-right: 43% !important;
    }
}





@media screen and (max-width:692px) and (orientation: landscape) {


    .AllPage{
        margin-top: 10%;
    }

    @media screen and (max-width:545px) and (orientation: landscape) {
        .AllPage{
            margin-top: 15%;
        }

    }

    @media screen and (max-width:545px) and (orientation: landscape) {
        .AllPage{
            margin-top: 22%;
        }

    }

    @media screen and (max-width:375px) and (orientation: landscape) {
        .AllPage{
            margin-top: 28%;
        }


    }

    @media screen and (max-width:320px) and (orientation: landscape) {
        .AllPage{
            margin-top: 45%;
        }


    }


    }

    @media screen and (max-width:620px) and (orientation: landscape)  {

        .fixed-top-2{
           top:11.6% !important
        }
    }


    .fixed-top-2 {
        position: fixed;
        top:10%;
        right: 0;
        left: 0;
        z-index: 1030;
    }

    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        color: #fff;
        background-color: white;

    }



    .nav-pills .nav-link.active{
        font-weight: bold !important;

    }

    .NoBold{
        font-weight: normal !important;
    }

    .nav {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        box-shadow: 0 8px 6px -11px black;
    }

    .ImgRight{

    }

    .PlusMieux{
        text-align: center !important ;
    }



    h1{
        font-family: 'Lato', sans-serif;

    }

    h2{
        font-family: 'Lato', sans-serif;

    }

    h5{
        font-family: 'Lato', sans-serif;
    }

    p{
        font-family: 'Lato', sans-serif;

    }
    .NavTabTitles{
        font-weight: bold;
    }
    .PrendsSoin{
        font-weight: bold;
    }
    .PriseTitle{
        font-weight: bold;
    }

/* second nav */

@media screen and (max-width:692px){

.AllPage{
    margin-top: 10%;
}

@media screen and (max-width:545px){
    .AllPage{
        margin-top: 15%;
    }
}

@media screen and (max-width:545px){
    .AllPage{
        margin-top: 22%;
    }

}

@media screen and (max-width:375px){
    .AllPage{
        margin-top: 28%;
    }

}

@media screen and (max-width:320px){
    .AllPage{
        margin-top: 45%;
    }
    .fixed-top-2{
        top:12% !important
     }

}


}

@media screen and (max-width:620px) {

    .fixed-top-2{
       top:11.6% !important
    }
}


.fixed-top-2 {
    position: fixed;
    top:10%;
    right: 0;
    left: 0;
    z-index: 1030;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: white;

}



.nav-pills .nav-link.active{
    font-weight: bold !important;

}

.NoBold{
    font-weight: normal !important;
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    box-shadow: 0 8px 6px -11px black;
}

.ImgRight{

}

.PlusMieux{
    text-align: center !important ;
}



h1{
    font-family: 'Lato', sans-serif;

}

h2{
    font-family: 'Lato', sans-serif;

}

h5{
    font-family: 'Lato', sans-serif;
}

p{
    font-family: 'Lato', sans-serif;

}
.NavTabTitles{
    font-weight: bold;
}
.PrendsSoin{
    font-weight: bold;
}
.PriseTitle{
    font-weight: bold;
}



/*  Mobiles   */



/* iphone 6 7 8  */

@media screen and (max-width:375px){





    /* bloc prise en main */

    .TransportText{
        text-align: center !important;
    }


       .PriseText{
           text-align: center !important;
       }
       .CenterText{
           text-align: center !important;
       }

}

/* iphone 6 7 8 plus */

@media screen and (max-width:414px){

/* Avantages Bloc */

.PlusMieux{
    text-align: center !important;
}


    /* Social Bloc */

    .fixed-top-2{
     top:8.6%
    }


        .SocialTtitle{
            text-align: center !important;
        }
        .SocialText{
            text-align: center !important ;
        }
        .titleBanane{
            text-align: center;
        }
        .textBanane{
            text-align: center !important;
        }


}

/* mobile 425px */

@media screen and (max-width:425px){
    .TabContent{
        margin-top: 35% !important;
    }
}

@media screen and (max-width:320px){


}

/*  Tablets   */

/* 768 pixels */


@media screen and (max-width:768px){

    /* bloc Social*/

    .Tab3{
    }
        .SocialTtitle{
            text-align: center !important;
        }
        .SocialText{
            text-align: center !important ;
        }
        .titleBanane{
            text-align: center;
        }
        .textBanane{
            text-align: center !important;
        }


        /* Bloc Avantages */


        .PlusMieux{
            text-align: center !important;

        }

       .avantages{
           width: 100%;
       }


       .textTitle{
        margin-top: -10%;
       }
       .TransportParag{
           text-align: center !important;
       }

       /* bloc prise en main */

       .TransportText{
        text-align: center !important;
    }


       .PriseText{
           text-align: center !important;
       }
       .CenterText{
           text-align: center !important;
       }

       .TabContent{
        margin-top: 10% !important;
    }
}


/*  ipad   */

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {

/* bloc avantage */

.PlusMieux{
    text-align: center !important;

}

.avantages{
   width: 100%;
}
.bloc2{
}
.TransportText{
   text-align: center !important;
}
.textTitle{
}
.TransportParag{
   text-align: center !important;
}

    /*  bloc social  */
    .fixed-top-2 {
        top:6%;
     }

     .Tab3{
    }
    .titleBanane{
        text-align: center;
    }



  }


  @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
  /* bloc social */
  .fixed-top-2{
      top:8% !important;
  }
  .titleBanane{
        text-align: center !important;
        margin-top: -9%;
    }
    .textBanane{
        text-align: center !important;
    }
    .SocialTtitle{
        text-align: center;
    }

}

/* Responsive page chauffeur */

@media screen and (max-width:1159px){

    /* Avantage Bloc  */

    .bloc0{
        display:'flex';
        flex-direction: column-reverse;
    }

    .bloc1{
        display:'flex';
        flex-direction: column-reverse;

    }
    .bloc2{
        display:'flex';
        flex-direction: column;

    }


    /* Social Bloc */

.bloc3{
    display:'flex';
    flex-direction: column-reverse;

}
.Img{
    width:70% !important;
    display: block;
  margin-left: 15% !important;
  margin-right: 15% !important;
}




.bloc4{
    display:'flex';
    flex-direction: column;

}

/* bloc prise en main */

.bloc5{
    display:'flex';
    flex-direction: column-reverse;

}
.bloc6{
    display:'flex';
    flex-direction: column;

}



}