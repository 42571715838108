@import url(https://fonts.googleapis.com/css?family=Poppins:400,700,800,900&display=swap);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);

* {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-container{
  height:400px;
  margin-right:15%;
  margin-left:15%;
  border-radius:15px;
  width:70%
}


/*

*/

.IconText{
    display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 1rem;
  margin-top: 6% ;
  margin-bottom: 6%;
  
}


@media screen and (max-width:625px){
    .carsouel{
        margin-top:5%
    }
}
@media screen and (max-width:474px){
    .carsouel{
        margin-top:12%
    }
}
@media screen and (max-width:475px){
    .carsouel{
        margin-top:12%
    }
}
@media screen and (max-width:570px) and (orientation:landscape){
    .carsouel{
        margin-top:9.5%
    }
}
.navbar-brand-second {
    display: inline-block;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}
.navbar-second {
    position: fixed;
    top: 0;
    z-index: 99999;
    
}
#ThirdNav{
    
    top: 0;
    width: 100%;
    transition: .5s;
    z-index: 9999;
}



.navbar-light .navbar-nav .nav-link {
    color: white !important;
}

 #MyLinkNav {
    will-change: transform;
    transition: -webkit-transform 450ms;
    transition: transform 450ms;
    transition: transform 450ms, -webkit-transform 450ms;
  }

   #MyLinkNav:hover {
    transition: -webkit-transform 125ms;
    transition: transform 125ms;
    transition: transform 125ms, -webkit-transform 125ms;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }
.ShadowNav{
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 37%);
}
 #UiCard {
    will-change: transform;
    transition: -webkit-transform 450ms;
    transition: transform 450ms;
    transition: transform 450ms, -webkit-transform 450ms;
  }

   #UiCard:hover {
    transition: -webkit-transform 125ms;
    transition: transform 125ms;
    transition: transform 125ms, -webkit-transform 125ms;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }




@media screen and (max-width: 992px){
.Mycol{
    margin-left:9.5%
}
.toptop{
    margin-top: 6%;
}
}

@media screen and (max-width: 576px){
    .MyRow{
        display: grid !important;
    }
    .Mycol{
        margin-left: 9.5% !important;
    }
    .toptop{
        margin-top: 6%;
    }
    }


    @media screen and (max-width: 425px){
        .Mycol{
            margin-right: 20% !important;
        }
    }
/*google font*/

*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Poppins',sans-serif;
}


/*slider*/
#carouselExampleIndicators{
	position: relative;
	height: 100vh;
	width: 100%;
}
#carouselExampleIndicators .carousel-inner{
	position: relative;
	height: 100%;
	width: 100%;
	background: #000;
}
#carouselExampleIndicators .carousel-inner:before{
	position: absolute;
	content: '';
	background: rgba(0, 0, 0, 0.45);
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
}
#carouselExampleIndicators .carousel-inner .carousel-item{
	position: relative;
	height: 100%;
	width:100%;
	background: url(/static/media/Parallax1.07d2f775.jpg) no-repeat center fixed;
	background-size: 100% 100%;
	background-position: center;
	background-attachment: fixed; /* pour anciens Chrome et Safari */
	background-size: cover;

}
#carouselExampleIndicators .carousel-inner .carousel-item:nth-child(2){
	background: url(/static/media/Parallax2.f0705ecd.jpg) no-repeat center fixed;
	background-size: 100% 100%;
	background-position: center;
	background-attachment: fixed; /* pour anciens Chrome et Safari */
	background-size: cover;
	
}
#carouselExampleIndicators .carousel-inner .carousel-item:nth-child(3){
	background: url(/static/media/Parallax3.6b258d4d.jpg) no-repeat center fixed;
	background-size: 100% 100%;
	background-position: center;
	background-attachment: fixed; /* pour anciens Chrome et Safari */
	background-size: cover;
;
}

#carouselExampleIndicators .carousel-inner .carousel-item .info{
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	height: auto;
	width: 100%;
	z-index: 1;
	text-align: center;
}
#carouselExampleIndicators .carousel-inner .carousel-item h1{
	height: auto;
	color: #fff;
	width: 100%;
	z-index: 1;
	text-align: center;
	font-size: 4.5em;
	font-weight: 750;
	font-family: 'Poppins',sans-serif !important;
}



#carouselExampleIndicators .carousel-inner .carousel-item p{
	height: auto;
	color: #ccc;
	width: 100%;
	z-index: 1;
	text-align: center;
	font-size: 1.2em;
	font-weight: 500;
}

@media screen and (max-width:475px){
	h1{
		font-size:2.5em !important;
	}
}
@media screen and (max-width:570px) and (orientation:landscape){
	h1{
		font-size:3.5em !important;

	}
}
@media screen and (max-width:768px) and (orientation:landscape){
	h1{
		font-size:3.5em !important;

	}
}

.text{
    text-align: center;
    font-family: 'Lato', sans-serif;

}


#five{
    color:white;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: white !important;
}

@media only screen and (max-width: 690px) {
    .CopyRightFooter{
        display:-webkit-flex;
        display:flex;
        -webkit-flex-direction:column !important;
                flex-direction:column !important;
        margin-left:28% !important;
    }
  }
#TopButton{
position:fixed;
bottom:40px;
right:40px;
font-size: 22px;
cursor: pointer;
z-index: 9999;
}
 #TopButton {
    will-change: transform;
    transition: -webkit-transform 450ms;
    transition: transform 450ms;
    transition: transform 450ms, -webkit-transform 450ms;
  }

   #TopButton:hover {
    transition: -webkit-transform 125ms;
    transition: transform 125ms;
    transition: transform 125ms, -webkit-transform 125ms;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }


@media screen and (max-width:992px){

    .ColParag{
        margin-top:4% !important ;
    }
    .ColParag2{
        margin-top:0% !important ;
    }
}

@media  screen and (max-width:470px  ){

    .ChauffeurTitle{
        font-size: 1.8em !important;
        margin-top: 3%;
    }
    .DivTitlle{
        margin-top: 28% !important;
    }
    .ChauffParallax{
        height:128px!important
    }
}

/* correction suplémentaires landscape */

@media screen and (max-width:570px) and (orientation: landscape) {
    .flex-row{
        top: 15% !important;
    }
}




@media screen and (max-width:1024px){
    .TransportText{
        margin-left: 11% !important
    }
}

@media screen and (max-width:768px){
    .TransportText{
        margin-right: 12%;
    }
}


@media screen and (max-width:320px){

  .row{
      width:90% !important;
      margin-left: 5% !important ;
      margin-right: 5% !important;
  }

    .BananaBloc{
        margin-right: 43% !important;
    }
}





@media screen and (max-width:692px) and (orientation: landscape) {


    .AllPage{
        margin-top: 10%;
    }

    @media screen and (max-width:545px) and (orientation: landscape) {
        .AllPage{
            margin-top: 15%;
        }

    }

    @media screen and (max-width:545px) and (orientation: landscape) {
        .AllPage{
            margin-top: 22%;
        }

    }

    @media screen and (max-width:375px) and (orientation: landscape) {
        .AllPage{
            margin-top: 28%;
        }


    }

    @media screen and (max-width:320px) and (orientation: landscape) {
        .AllPage{
            margin-top: 45%;
        }


    }


    }

    @media screen and (max-width:620px) and (orientation: landscape)  {

        .fixed-top-2{
           top:11.6% !important
        }
    }


    .fixed-top-2 {
        position: fixed;
        top:10%;
        right: 0;
        left: 0;
        z-index: 1030;
    }

    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        color: #fff;
        background-color: white;

    }



    .nav-pills .nav-link.active{
        font-weight: bold !important;

    }

    .NoBold{
        font-weight: normal !important;
    }

    .nav {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        box-shadow: 0 8px 6px -11px black;
    }

    .ImgRight{

    }

    .PlusMieux{
        text-align: center !important ;
    }



    h1{
        font-family: 'Lato', sans-serif;

    }

    h2{
        font-family: 'Lato', sans-serif;

    }

    h5{
        font-family: 'Lato', sans-serif;
    }

    p{
        font-family: 'Lato', sans-serif;

    }
    .NavTabTitles{
        font-weight: bold;
    }
    .PrendsSoin{
        font-weight: bold;
    }
    .PriseTitle{
        font-weight: bold;
    }

/* second nav */

@media screen and (max-width:692px){

.AllPage{
    margin-top: 10%;
}

@media screen and (max-width:545px){
    .AllPage{
        margin-top: 15%;
    }
}

@media screen and (max-width:545px){
    .AllPage{
        margin-top: 22%;
    }

}

@media screen and (max-width:375px){
    .AllPage{
        margin-top: 28%;
    }

}

@media screen and (max-width:320px){
    .AllPage{
        margin-top: 45%;
    }
    .fixed-top-2{
        top:12% !important
     }

}


}

@media screen and (max-width:620px) {

    .fixed-top-2{
       top:11.6% !important
    }
}


.fixed-top-2 {
    position: fixed;
    top:10%;
    right: 0;
    left: 0;
    z-index: 1030;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: white;

}



.nav-pills .nav-link.active{
    font-weight: bold !important;

}

.NoBold{
    font-weight: normal !important;
}

.nav {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    box-shadow: 0 8px 6px -11px black;
}

.ImgRight{

}

.PlusMieux{
    text-align: center !important ;
}



h1{
    font-family: 'Lato', sans-serif;

}

h2{
    font-family: 'Lato', sans-serif;

}

h5{
    font-family: 'Lato', sans-serif;
}

p{
    font-family: 'Lato', sans-serif;

}
.NavTabTitles{
    font-weight: bold;
}
.PrendsSoin{
    font-weight: bold;
}
.PriseTitle{
    font-weight: bold;
}



/*  Mobiles   */



/* iphone 6 7 8  */

@media screen and (max-width:375px){





    /* bloc prise en main */

    .TransportText{
        text-align: center !important;
    }


       .PriseText{
           text-align: center !important;
       }
       .CenterText{
           text-align: center !important;
       }

}

/* iphone 6 7 8 plus */

@media screen and (max-width:414px){

/* Avantages Bloc */

.PlusMieux{
    text-align: center !important;
}


    /* Social Bloc */

    .fixed-top-2{
     top:8.6%
    }


        .SocialTtitle{
            text-align: center !important;
        }
        .SocialText{
            text-align: center !important ;
        }
        .titleBanane{
            text-align: center;
        }
        .textBanane{
            text-align: center !important;
        }


}

/* mobile 425px */

@media screen and (max-width:425px){
    .TabContent{
        margin-top: 35% !important;
    }
}

@media screen and (max-width:320px){


}

/*  Tablets   */

/* 768 pixels */


@media screen and (max-width:768px){

    /* bloc Social*/

    .Tab3{
    }
        .SocialTtitle{
            text-align: center !important;
        }
        .SocialText{
            text-align: center !important ;
        }
        .titleBanane{
            text-align: center;
        }
        .textBanane{
            text-align: center !important;
        }


        /* Bloc Avantages */


        .PlusMieux{
            text-align: center !important;

        }

       .avantages{
           width: 100%;
       }


       .textTitle{
        margin-top: -10%;
       }
       .TransportParag{
           text-align: center !important;
       }

       /* bloc prise en main */

       .TransportText{
        text-align: center !important;
    }


       .PriseText{
           text-align: center !important;
       }
       .CenterText{
           text-align: center !important;
       }

       .TabContent{
        margin-top: 10% !important;
    }
}


/*  ipad   */

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {

/* bloc avantage */

.PlusMieux{
    text-align: center !important;

}

.avantages{
   width: 100%;
}
.bloc2{
}
.TransportText{
   text-align: center !important;
}
.textTitle{
}
.TransportParag{
   text-align: center !important;
}

    /*  bloc social  */
    .fixed-top-2 {
        top:6%;
     }

     .Tab3{
    }
    .titleBanane{
        text-align: center;
    }



  }


  @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
  /* bloc social */
  .fixed-top-2{
      top:8% !important;
  }
  .titleBanane{
        text-align: center !important;
        margin-top: -9%;
    }
    .textBanane{
        text-align: center !important;
    }
    .SocialTtitle{
        text-align: center;
    }

}

/* Responsive page chauffeur */

@media screen and (max-width:1159px){

    /* Avantage Bloc  */

    .bloc0{
        display:'flex';
        -webkit-flex-direction: column-reverse;
                flex-direction: column-reverse;
    }

    .bloc1{
        display:'flex';
        -webkit-flex-direction: column-reverse;
                flex-direction: column-reverse;

    }
    .bloc2{
        display:'flex';
        -webkit-flex-direction: column;
                flex-direction: column;

    }


    /* Social Bloc */

.bloc3{
    display:'flex';
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;

}
.Img{
    width:70% !important;
    display: block;
  margin-left: 15% !important;
  margin-right: 15% !important;
}




.bloc4{
    display:'flex';
    -webkit-flex-direction: column;
            flex-direction: column;

}

/* bloc prise en main */

.bloc5{
    display:'flex';
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;

}
.bloc6{
    display:'flex';
    -webkit-flex-direction: column;
            flex-direction: column;

}



}
.MuiAppBar-root {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    z-index: 1100;
    box-sizing: border-box;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: fixed;
}

@media screen and (max-width:740px){
    .plafond{
        margin-top: 80px !important;
    }
}
@media screen and (max-width:330px){
    .plafond{
        margin-top: 100px !important;
    }
}
.PrivateTabIndicator-colorPrimary-3 {
    background-color: #82bb25 !important;
}

@media  screen and (max-width:470px  ){

    .RestoParallax{
height: 128px !important;
    }

    .RestaurantTitle{
        font-size: 1.8em !important;
        margin-top: 3%;
    }
    .DivTite{
        margin-top: 28% !important;
    }
}


.MuiBox-root {
    padding: 0px !important;
}
.bg { 
    
    /* The image used */
    background-image: url(/static/media/client.947d7585.jpg);
  
    /* Full height */
    height: 100%; 
  
    /* Center and scale the image nicely */
    background-repeat: no-repeat;
    background-size: cover;
  }

.LocationTtitle{
    height: auto;
    width: 100%;
    z-index: 1;
    text-align: left;
    font-size: 4.5em;
    font-weight: 750;
    font-family: 'Poppins',sans-serif !important;
    margin-top:15%;
    margin-left:4%;
    color:white;

}

@media  screen and (max-width:470px  ){
    .MyParallax2{
        height:128px !important;
    }

}

@media  screen and (max-width:470px  ){

    .LocationTtitle{
        font-size: 1.2em !important;

    }
    .TitleLocation{
        margin-top: 28% !important;
    }
    .GpsImg{
        height:30px;
        width:30px;
    }
}

@media  screen and (max-width:571px  ){

    .LocationTtitle{
        font-size: 3.2em
    }

}

.leaflet-touch .leaflet-bar a {
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: black !important;
}
.leaflet-container a {
    color: #0078A8 !important;
}
.newsletter {
padding: 80px 0;
background: #f2f2f2;
}

.newsletter .content {
max-width: 650px;
margin: 0 auto;
text-align: center;
position: relative;
z-index: 2; }
.newsletter .content h2 {
color: #243c4f;
margin-bottom: 40px; }
.newsletter .content .form-control {
height: 50px;
border-color: #ffffff;
border-radius:0;
}
.newsletter .content.form-control:focus {
box-shadow: none;
border: 2px solid #243c4f;
}
.newsletter .content .btn {
min-height: 50px;
border-radius:0;
background: #243c4f;
color: #fff;
font-weight:600;
}
.row {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

@media  screen and (max-width:470px  ){
    .MyParallax{
        height:128px !important;
    }
    .AproposTitle{
        font-size: 2em !important;
        margin-top: 3%;
    }
}

@media  screen and (max-width:470px  ){

    .AproposTitle{
        font-size: 1.8em !important;
        margin-top: 3%;
    }
    .DivTitle{
        margin-top: 28% !important;
    }
}


#team {
    background: #eee !important;
}

.btn-primary:hover,
.btn-primary:focus {
    background-color: #108d6f;
    border-color: #108d6f;
    box-shadow: none;
    outline: none;
}

.btn-primary {
    color: #fff;
    background-color: #007b5e;
    border-color: #007b5e;
}

section {
    padding: 60px 0;
}

section .section-title {
    text-align: center;
    color: #007b5e;
    margin-bottom: 50px;
    text-transform: uppercase;
}

#team .card {
    border: none;
    background: #ffffff;
}

.image-flip:hover .backside,
.image-flip.hover .backside {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    border-radius: .25rem;
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.mainflip {
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -ms-transition: 1s;
    -moz-transition: 1s;
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
}

.frontside {
    position: relative;
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    z-index: 2;
    margin-bottom: 30px;
}

.backside {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

.frontside,
.backside {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -moz-transition: 1s;
    -moz-transform-style: preserve-3d;
    -o-transition: 1s;
    -o-transform-style: preserve-3d;
    -ms-transition: 1s;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
}

.frontside .card,
.backside .card {
    min-height: 312px;
}

.backside .card a {
    font-size: 18px;
    color: #007b5e !important;
}

.frontside .card .card-title,
.backside .card .card-title {
    color: #007b5e !important;
}

.frontside .card .card-body img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}
.makeStyles-paper-1 {
    display: -webkit-flex;
    display: flex;
    margin-top: 0px !important;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}
@media  screen and (max-width:470px  ){

    .ClientTitle{
        font-size: 1.5em !important;
        margin-top: 3% !important;
    }
    .DivTitllle{
        margin-top: 28% !important;
    }
    .ClientParallax{
        height:128px !important
    }
}


.MuiAppBar-root {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    z-index: 1100;
    box-sizing: border-box;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: fixed;
}

@media screen and (max-width:740px){
    .plafond{
        margin-top: 80px !important;
    }
}
@media screen and (max-width:330px){
    .plafond{
        margin-top: 100px !important;
    }
}
.PrivateTabIndicator-colorPrimary-3 {
    background-color: #82bb25 !important;
}

 .bd-placeholder-img {
        font-size: 1.125rem;
        text-anchor: middle;
        -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
      }

      @media (min-width: 768px) {
        .bd-placeholder-img-lg {
          font-size: 3.5rem;
        }
      }
.MyMiniBar {
    color: #fff;
    background-color: #82bb25 !important;
}

@media screen and (max-width:1191px){
    .ServiceTitle{
        font-size:3.5em !important;
    }
}
@media screen and (max-width:938px){
    .ServiceTitle{
        font-size:2.5em !important;
    }
}
@media  screen and (max-width:470px  ){
    .MyParallax{
        height:128px !important;
    }
    .AproposTitle{
        font-size: 2em !important;
        margin-top: 3%;
    }
}

@media  screen and (max-width:470px  ){

    .AproposTitle{
        font-size: 1.8em !important;
        margin-top: 3%;
    }
    .DivTitle{
        margin-top: 28% !important;
    }
}

.JobsCard{
    width:100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-gap: 3rem;
    margin-top: 6% ;
    margin-bottom: 6%;

    justify-items:center;

}

.BarJob {
    background-color: white !important;
}

.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }
}
#UiCard {
    will-change: transform;
    transition: -webkit-transform 450ms;
    transition: transform 450ms;
    transition: transform 450ms, -webkit-transform 450ms;
    cursor: pointer
}

#UiCard:hover {
    transition: -webkit-transform 125ms;
    transition: transform 125ms;
    transition: transform 125ms, -webkit-transform 125ms;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
}

