@media  screen and (max-width:470px  ){
    .MyParallax{
        height:128px !important;
    }
    .AproposTitle{
        font-size: 2em !important;
        margin-top: 3%;
    }
}

@media  screen and (max-width:470px  ){

    .AproposTitle{
        font-size: 1.8em !important;
        margin-top: 3%;
    }
    .DivTitle{
        margin-top: 28% !important;
    }
}

.JobsCard{
    width:100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-gap: 3rem;
    margin-top: 6% ;
    margin-bottom: 6%;

    justify-items:center;

}

.BarJob {
    background-color: white !important;
}
