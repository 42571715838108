@media  screen and (max-width:470px  ){

    .ClientTitle{
        font-size: 1.5em !important;
        margin-top: 3% !important;
    }
    .DivTitllle{
        margin-top: 28% !important;
    }
    .ClientParallax{
        height:128px !important
    }
}

